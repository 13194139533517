import React from 'react';
import { Link } from 'react-router-dom';

function Login() {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg p-8 w-96">
        <h2 className="text-2xl font-semibold mb-4">Login</h2>
        <p className="text-sm mb-6">
          or <Link to="/signup" className="text-blue-500 font-bold">create an account</Link>
        </p>
        <input
          type="text"
          placeholder="Phone Number"
          className="w-full border rounded p-2 mb-4"
        />
        <button
          className="w-full bg-gray-300 text-gray-700 py-2 rounded"

        >
          LOGIN
        </button>
        <p className="text-sm mt-4">
          By egg clicking on Login, I accept the{' '}
          <a href="/#" className="text-black font-bold">Terms & Conditions</a> &{' '}
          <a href="/#" className="text-black font-bold">Privacy Policy</a>.
        </p>
      </div>
    </div>
  );
}

export default Login;
