import React from 'react';
import { Link } from 'react-router-dom';

const Signup = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white w-full max-w-md p-6 rounded-lg shadow-md">
        <button className="absolute top-4 right-4 text-gray-500 text-xl">&times;</button>
        <h2 className="text-2xl font-bold mb-4">Sign up</h2>
        <p className="text-sm text-gray-600 mb-6">
          or <Link to="/login" className="text-blue-500 font-bold">login to your account..</Link>
        </p>
        <input
          type="text"
          placeholder="Phone Number"
          className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="text"
          placeholder="Name"
          className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <input
          type="email"
          placeholder="Email"
          className="w-full px-4 py-2 mb-4 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <p className="text-sm text-customBlue font-bold mb-4 cursor-pointer">Have a referral code?</p>
        <button
          className="w-full py-2 bg-gray-300 text-gray-500 font-semibold rounded-md "

        >
          CONTINUE
        </button>
        <p className="text-xs text-gray-500 mt-4">
          By clicking on Continue, I accept the{' '}
          <a href="#" className="text-black font-bold">Terms & Conditions</a> &{' '}
          <a href="#" className="text-black font-bold">Privacy Policy</a>
        </p>
      </div>
    </div>
  );
};

export default Signup;
