import './App.css';
import {BrowserRouter,Routes,Route} from "react-router-dom"
import Signup from './pages/Signup';
import Login from "./pages/Login"

function App() {
  return (
    <div >
         
         <BrowserRouter>

           <Routes>
             <Route exact path="/signup"element={<Signup/>}/>
             <Route exact path="/login" element={<Login/>}/>
           </Routes>
         
         </BrowserRouter>
         
    </div>
  );
}

export default App;
